import { Modal, Input, Button, Space, Typography, Tabs, Divider, Slider, Tag, Radio, InputNumber, Checkbox, App } from 'antd';
import { FormOutlined, EditOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { DesignContext } from '../../pages/Design';
import { ProfileContext } from '../../pages/Profiles';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { Line } from '@ant-design/charts';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function ModalProfileEdit() {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { Zones } = useContext(DesignContext);
  const { SelectedProfile, GetProfiles, ProfileEditModalOpen, setProfileEditModalOpen } = useContext(ProfileContext);
  const { modal } = App.useApp();

  const [Loading, setLoading] = useState(false);
  const [ProfileIndex, setProfileIndex] = useState(-1);
  const [ProfileName, setProfileName] = useState('');
  const [ProfileContent, setProfileContent] = useState({});
  const [UseSceneCount, setUseSceneCount] = useState(0);
  const [UseMotionSensor, setUseMotionSensor] = useState(false);
  const [UseAmbientLightSensor, setUseAmbientLightSensor] = useState(false);
  const [UseColorTunableLight, setUseColorTunableLight] = useState(false);
  const [ProfileNameWarnning, setProfileNameWarnning] = useState(false);
  const [TabActiveKey, setTabActiveKey] = useState(0);

  const DEFAULT_VALUE = {
    DEFAULT_LIGHT_LEVEL: {
      LIGHT_LEVEL: 100,
      TRANSITION_TIME: 1
    },
    LIGHT_LC: {
      RUN: {
        LIGHT_LEVEL: 100,
        FADE_ON_TIME: 1,
        TIMEOUT: 30,
        AMBIENT_LIGHT: 1000
      },
      PROLONG: {
        LIGHT_LEVEL: 30,
        FADE_TIME: 1,
        TIMEOUT: 10,
        AMBIENT_LIGHT: 500
      },
      STANDBY: {
        LIGHT_LEVEL: 10,
        FADE_STANDBY_AUTO_TIME: 1,
        AMBIENT_LIGHT: 100
      }
    },
    LIGHT_CTL: {
      TEMPERATURE: {
        MIN: 2700,
        MAX: 6500
      },
      LIGHTNESS: {
        MIN: 0,
        MAX: 100
      }
    },
    SCENE: {
      LIGHT_LEVEL: 50,
      NUMBER_1: 80,
      NUMBER_2: 50,
      NUMBER_3: 20
    }
  };

  const MARKS_PERCENT = {
    0: '0%',
    50: '50%',
    100: '100%',
  };

  const MARKS_TIMEOUT = {
    1: '1' + t('modal.profile.edit.unit.sec'),
    300: '5' + t('modal.profile.edit.unit.min'),
    600: '10' + t('modal.profile.edit.unit.min')
  };

  const MARKS_AMBIENT_LIGHT = {
    0: '0lux',
    500: '500lux',
    1000: '1,000lux'
  };

  const MARKS_LIGHT_TEMPERATURE = {
    1000: "1,000K",
    2700: "2,700K",
    6500: "6,500K",
    10000: "10,000K"
  };

  const onClick_Modal = () => {
    setProfileNameWarnning(false);
    setTabActiveKey(0);

    if (SelectedProfile != null) {
      setProfileIndex(SelectedProfile.pf_idx);
      setProfileName(SelectedProfile.pf_name);
      GetProfileContent(SelectedProfile.pf_idx);
      setProfileEditModalOpen(true);
    }
  };

  const onCancel_Modal = () => {
    setProfileEditModalOpen(false);
  };

  const onOk_Modal = () => {
    if (ProfileName.length === 0) {
      setProfileNameWarnning(true);
      return;
    }

    modal.warning({
      content: t('modal.profile.edit.warnning.confirm'),
      okText: t('modal.button.ok'),
      cancelText: t('modal.button.cancel'),
      onOk: () => { UpdateProfileContent(); }
    });
  };

  const onChange_ProfileName = (e) => {
    setProfileNameWarnning(false);
    setProfileName(e.target.value);
  };

  const onChange_TabActiveKey = (value) => {
    setTabActiveKey(value);
  };

  const onChange_UseMotionSensor = () => {
    setUseMotionSensor(!UseMotionSensor);

    if (!ProfileContent.hasOwnProperty('run') || !ProfileContent.hasOwnProperty('prolong') || !ProfileContent.hasOwnProperty('standby')) {
      setProfileContent({
        ...ProfileContent,
        run: {
          light_level: DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL,
          fade_on_time: DEFAULT_VALUE.LIGHT_LC.RUN.FADE_ON_TIME,
          timeout: DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT
        },
        prolong: {
          light_level: DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL,
          fade_time: DEFAULT_VALUE.LIGHT_LC.PROLONG.FADE_TIME,
          timeout: DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT
        },
        standby: {
          light_level: DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL,
          fade_standby_auto_time: DEFAULT_VALUE.LIGHT_LC.STANDBY.FADE_STANDBY_AUTO_TIME,
        }
      });
    }
  };

  const onChange_UseAmbientLightSensor = () => {
    setUseAmbientLightSensor(!UseAmbientLightSensor);

    if (!ProfileContent.hasOwnProperty('lux_level')) {
      setProfileContent({
        ...ProfileContent,
        lux_level: {
          run: DEFAULT_VALUE.LIGHT_LC.RUN.AMBIENT_LIGHT,
          prolong: DEFAULT_VALUE.LIGHT_LC.PROLONG.AMBIENT_LIGHT,
          standby: DEFAULT_VALUE.LIGHT_LC.STANDBY.AMBIENT_LIGHT
        }
      });
    }
  };

  const onChange_UseColorTunableLight = () => {
    setUseColorTunableLight(!UseColorTunableLight);

    let content = { ...ProfileContent };
    if (!ProfileContent.hasOwnProperty('temperature_range')) {
      content.temperature_range = {
        min: DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MIN,
        max: DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MAX
      };
    }
    if (!ProfileContent.hasOwnProperty('lightness_range')) {
      content.lightness_range = {
        min: DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MIN,
        max: DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MAX
      };
    }

    setProfileContent(content);
  };

  // const onChange_DefaultLightLevel_LightLevel = (value) => {
  //   let content = {
  //     ...ProfileContent,
  //     default_light_level: {
  //       light_level: value,
  //       transition_time: DEFAULT_VALUE.DEFAULT_LIGHT_LEVEL.TRANSITION_TIME
  //     }
  //   };
  //   if (ProfileContent.hasOwnProperty('default_light_level')) {
  //     if (ProfileContent.default_light_level.hasOwnProperty('transition_time')) {
  //       content.default_light_level.transition_time = ProfileContent.default_light_level.transition_time;
  //     }
  //   }

  //   setProfileContent(content);
  // };

  const onChange_PowerUpBehavior = (e) => {
    setProfileContent({
      ...ProfileContent,
      power_up_behavior: e.target.value
    });
  };

  const onChange_LuxLevel_Run = (value) => {
    let content = { ...ProfileContent };
    content.lux_level.run = value;
    setProfileContent(content);
  };

  const onChange_LuxLevel_Prolong = (value) => {
    let content = { ...ProfileContent };
    content.lux_level.prolong = value;
    setProfileContent(content);
  };

  const onChange_LuxLevel_Standby = (value) => {
    let content = { ...ProfileContent };
    content.lux_level.standby = value;
    setProfileContent(content);
  };

  const onChange_Run_LightLevel = (value) => {
    let content = {
      ...ProfileContent,
      run: {
        light_level: value,
        fade_on_time: DEFAULT_VALUE.LIGHT_LC.RUN.FADE_ON_TIME,
        timeout: DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT
      }
    };
    if (ProfileContent.hasOwnProperty('run')) {
      if (ProfileContent.run.hasOwnProperty('fade_on_time')) {
        content.run.fade_on_time = ProfileContent.run.fade_on_time;
      }
      if (ProfileContent.run.hasOwnProperty('timeout')) {
        content.run.timeout = ProfileContent.run.timeout;
      }
    }

    setProfileContent(content);
  };

  const onChange_Run_Timeout = (value) => {
    let content = {
      ...ProfileContent,
      run: {
        light_level: DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL,
        fade_on_time: DEFAULT_VALUE.LIGHT_LC.RUN.FADE_ON_TIME,
        timeout: value
      }
    };
    if (ProfileContent.hasOwnProperty('run')) {
      if (ProfileContent.run.hasOwnProperty('light_level')) {
        content.run.light_level = ProfileContent.run.light_level;
      }
      if (ProfileContent.run.hasOwnProperty('fade_on_time')) {
        content.run.fade_on_time = ProfileContent.run.fade_on_time;
      }
    }

    setProfileContent(content);
  };

  const onChange_Prolong_LightLevel = (value) => {
    let content = {
      ...ProfileContent,
      prolong: {
        light_level: value,
        fade_time: DEFAULT_VALUE.LIGHT_LC.PROLONG.FADE_TIME,
        timeout: DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT
      }
    }
    if (ProfileContent.hasOwnProperty('prolong')) {
      if (ProfileContent.prolong.hasOwnProperty('fade_time')) {
        content.prolong.fade_time = ProfileContent.prolong.fade_time;
      }
      if (ProfileContent.prolong.hasOwnProperty('timeout')) {
        content.prolong.timeout = ProfileContent.prolong.timeout;
      }
    }

    setProfileContent(content);
  };

  const onChange_Prolong_Timeout = (value) => {
    let content = {
      ...ProfileContent,
      prolong: {
        light_level: DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL,
        fade_time: DEFAULT_VALUE.LIGHT_LC.PROLONG.FADE_TIME,
        timeout: value
      }
    };
    if (ProfileContent.hasOwnProperty('prolong')) {
      if (ProfileContent.prolong.hasOwnProperty('light_level')) {
        content.prolong.light_level = ProfileContent.prolong.light_level;
      }
      if (ProfileContent.prolong.hasOwnProperty('fade_time')) {
        content.prolong.fade_time = ProfileContent.prolong.fade_time;
      }
    }

    setProfileContent(content);
  };

  const onChange_Standby_LightLevel = (value) => {
    let content = {
      ...ProfileContent,
      standby: {
        light_level: value,
        fade_standby_auto_time: DEFAULT_VALUE.LIGHT_LC.STANDBY.FADE_STANDBY_AUTO_TIME,
      }
    };
    if (ProfileContent.hasOwnProperty('standby')) {
      if (ProfileContent.standby.hasOwnProperty('fade_standby_auto_time')) {
        content.standby.fade_standby_auto_time = ProfileContent.standby.fade_standby_auto_time;
      }
    }

    setProfileContent(content);
  };

  const onChange_UseSceneCount = (value) => {
    setUseSceneCount(value);

    let scenes = [];
    for (let i = 0; i < 3; i++) {
      if (ProfileContent.hasOwnProperty('scenes')) {
        if (ProfileContent.scenes[i] != null) {
          scenes.push(ProfileContent.scenes[i]);
          continue;
        }
      }

      scenes.push({
        name: 'Scene ' + (i + 1).toString(),
        light_level: DEFAULT_VALUE.SCENE.LIGHT_LEVEL
      });
    }

    setProfileContent({
      ...ProfileContent,
      scenes: scenes
    });
  };

  const onChange_SceneLightlevel = (s_number, light_level) => {
    if (ProfileContent.hasOwnProperty('scenes')) {
      let scenes = ProfileContent.scenes;
      scenes[s_number].light_level = light_level;
      setProfileContent({
        ...ProfileContent,
        scenes: scenes
      });
    }
  };

  const onChange_SceneName = (s_number, s_name) => {
    if (ProfileContent.hasOwnProperty('scenes')) {
      let scenes = ProfileContent.scenes;
      scenes[s_number].name = s_name;
      setProfileContent({
        ...ProfileContent,
        scenes: scenes
      });
    }
  };

  const onChange_TemperatureRange = (value) => {
    let content = {
      ...ProfileContent,
      temperature_range: {
        min: (value[0] != null) ? value[0] : ProfileContent.temperature_range.min,
        max: (value[1] != null) ? value[1] : ProfileContent.temperature_range.max
      }
    }
    if (ProfileContent.hasOwnProperty('temperature_range')) {
      if ((value[0] == null) && ProfileContent.temperature_range.hasOwnProperty('min')) {
        content.temperature_range.min = ProfileContent.temperature_range.min;
      }
      if ((value[1] == null) && ProfileContent.temperature_range.hasOwnProperty('max')) {
        content.temperature_range.max = ProfileContent.temperature_range.max;
      }
    }

    setProfileContent(content);
  };

  const onChange_LightnessRange = (value) => {
    let content = {
      ...ProfileContent,
      lightness_range: {
        min: (value[0] != null) ? value[0] : ProfileContent.lightness_range.min,
        max: (value[1] != null) ? value[1] : ProfileContent.lightness_range.max
      }
    }
    if (ProfileContent.hasOwnProperty('lightness_range')) {
      if ((value[0] == null) && ProfileContent.lightness_range.hasOwnProperty('min')) {
        content.lightness_range.min = ProfileContent.lightness_range.min;
      }
      if ((value[1] == null) && ProfileContent.lightness_range.hasOwnProperty('max')) {
        content.lightness_range.max = ProfileContent.lightness_range.max;
      }
    }

    setProfileContent(content);
  };

  const GetProfileContent = (pf_idx) => {
    let i = 0;
    for (; i < Zones.length; i++) {
      if (Zones[i] != null) {
        break;
      }
    }

    ApiMain(API_LIST.PROFILE_GET, METHOD.POST, {
      id: ID,
      z_idx: Zones[i].z_idx,
      pf_idx: pf_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          setProfileContent(res.data.content);
          setUseSceneCount(res.data.content.hasOwnProperty('scenes') ? res.data.content.scenes.length : 0);
          setUseMotionSensor(res.data.content.hasOwnProperty('run') ? true : false);
          setUseAmbientLightSensor(res.data.content.hasOwnProperty('lux_level') ? true : false);
          setUseColorTunableLight((res.data.content.hasOwnProperty('temperature_range') || res.data.content.hasOwnProperty('lightness_range')) ? true : false)
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const UpdateProfileContent = () => {
    let profile = ProfileContent;
    let scenes = [];
    for (let i = 0; i < UseSceneCount; i++) {
      scenes.push(ProfileContent.scenes[i]);
    }
    profile.scenes = scenes;

    if (!profile.hasOwnProperty("power_up_behavior")) {
      profile.power_up_behavior = 2;
    }

    if (!UseMotionSensor) {
      delete profile.run;
      delete profile.prolong;
      delete profile.standby;
    }
    if (!UseAmbientLightSensor) {
      delete profile.lux_level;
    }
    setProfileContent(profile);

    let i = 0;
    for (; i < Zones.length; i++) {
      if (Zones[i] != null) {
        break;
      }
    }

    setLoading(true);
    ApiMain(API_LIST.PROFILE_UPDATE, METHOD.POST, {
      id: ID,
      z_idx: Zones[i].z_idx,
      pf_idx: ProfileIndex,
      pf_name: ProfileName,
      content: JSON.stringify(profile)
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.profile.edit.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              GetProfiles();
              setProfileEditModalOpen(false);
            }
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.profile.edit.warnning.no_content'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;

        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    if (ProfileEditModalOpen) {
      onClick_Modal();
    }
  }, [ProfileEditModalOpen]);

  return (
    <>
      <Button icon={<FormOutlined />} disabled={(SelectedProfile == null) ? true : false} onClick={onClick_Modal}>{t('modal.profile.edit.button')}</Button>

      <Modal closable={false} footer={null} open={ProfileEditModalOpen} confirmLoading={Loading}
        title={
          <Space>
            <EditOutlined />
            {t('modal.profile.edit.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.profile.edit.profile_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={ProfileName} onChange={onChange_ProfileName} />
              {ProfileNameWarnning ? <Text className='modal_error_text'>{t('modal.profile.edit.warnning.no_profile_name')}</Text> : null}
            </Space>
          </Space>
        </Space>

        <Divider className='modal_divider' />

        <Tabs activeKey={TabActiveKey} onChange={onChange_TabActiveKey}
          items={[{
            key: 0,
            label: t('modal.profile.edit.tabs.normal'),
            children:
              <Space direction='vertical'>
                <Space>
                  <Divider className='modal_divider' type='vertical' />
                  <Text className='profile_description'>{t('modal.profile.edit.description.normal')}</Text>
                </Space>

                {/* <Space className='profile_subcontent'>
                  <Space direction='vertical' size={10}>
                    <Text className='profile_subtitle'>설치 후 초기밝기</Text>
                    <Space className='space_between'>
                      <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>밝기</Tag>
                      <Slider className='profile_slider' min={0} max={100} marks={marks_percent} onChange={onChange_DefaultLightLevel_LightLevel}
                        value={(ProfileContent.hasOwnProperty('default_light_level') ? ProfileContent.default_light_level.light_level : 100)} />
                      <InputNumber min={0} max={100} style={{ width: 80, marginLeft: 10 }} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={onChange_DefaultLightLevel_LightLevel}
                        value={(ProfileContent.hasOwnProperty('default_light_level') ? ProfileContent.default_light_level.light_level : 100)} />
                    </Space>
                  </Space>
                </Space> */}
                <Space className='profile_subcontent'>
                  <Space direction='vertical' size={10}>
                    <Text className='profile_subtitle'>{t('modal.profile.edit.power_on_behavior.subtitle')}</Text>
                    <Space>
                      <Radio.Group value={ProfileContent.hasOwnProperty('power_up_behavior') ? ProfileContent.power_up_behavior : 2} onChange={onChange_PowerUpBehavior}>
                        <Space direction='vertical'>
                          <Radio value={2}>{t('modal.profile.edit.power_on_behavior.component.restore')}</Radio>
                          <Radio value={1}>{t('modal.profile.edit.power_on_behavior.component.on')}</Radio>
                          <Radio value={0}>{t('modal.profile.edit.power_on_behavior.component.off')}</Radio>
                        </Space>
                      </Radio.Group>
                    </Space>
                  </Space>
                </Space>
                <Space className='profile_subcontent'>
                  <Space direction='vertical' size={10}>
                    <Text className='profile_subtitle'>{t('modal.profile.edit.light_range.subtitle.lightness_range')}</Text>
                    <Space className='space_center' direction='vertical' size={3} style={{ width: 440 }}>
                      <Space>
                        <Slider className='profile_slider_ctl' range={true} min={0} max={100} marks={MARKS_PERCENT} onChange={onChange_LightnessRange}
                          value={[(ProfileContent.hasOwnProperty('lightness_range') ? ProfileContent.lightness_range.min : DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MIN),
                          (ProfileContent.hasOwnProperty('lightness_range') ? ProfileContent.lightness_range.max : DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MAX)]} />
                      </Space>
                      <Space>
                        <Space>
                          <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.light_range.component.min')}</Tag>
                          <InputNumber className='profile_inputnumber_ctl' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={(value) => onChange_LightnessRange([value, null])}
                            value={(ProfileContent.hasOwnProperty('lightness_range') ? ProfileContent.lightness_range.min : DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MIN)} />
                        </Space>
                        <Space style={{ marginLeft: 20 }}>
                          <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.light_range.component.max')}</Tag>
                          <InputNumber className='profile_inputnumber_ctl' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={(value) => onChange_LightnessRange([null, value])}
                            value={(ProfileContent.hasOwnProperty('lightness_range') ? ProfileContent.lightness_range.max : DEFAULT_VALUE.LIGHT_CTL.LIGHTNESS.MAX)} />
                        </Space>
                      </Space>
                    </Space>
                  </Space>
                </Space>
              </Space>
          }, {
            key: 1,
            label: t('modal.profile.edit.tabs.scene'),
            children:
              <Space direction='vertical'>
                <Space>
                  <Divider className='modal_divider' type='vertical' />
                  <Text className='profile_description'>{t('modal.profile.edit.description.scene')}</Text>
                </Space>

                <Space className='space_between profile_subcontent'>
                  <Text className='profile_subtitle'>{t('modal.profile.edit.scene.subtitle.number_of_scene')}</Text>
                  <InputNumber className='profile_inputnumber' min={0} max={3} value={UseSceneCount} onChange={onChange_UseSceneCount} />
                </Space>
                {ProfileContent.hasOwnProperty('scenes') ? (((ProfileContent.scenes[0] != null) && (UseSceneCount >= 1)) ?
                  <Space className='profile_subcontent'>
                    <Space direction='vertical' size={10}>
                      <Space className='profile_subtitle_space space_between'>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.scene.subtitle.scene_1_name')}</Text>
                        <Input className='profile_input_scene_name' value={ProfileContent.scenes[0].name} maxLength={10} onChange={(e) => onChange_SceneName(0, e.target.value)} />
                      </Space>
                      <Space>
                        <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.scene.component.lightness')}</Tag>
                        <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={(value) => onChange_SceneLightlevel(0, value)}
                          value={ProfileContent.scenes[0].light_level} />
                        <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={(value) => onChange_SceneLightlevel(0, value)}
                          value={ProfileContent.scenes[0].light_level} />
                      </Space>
                    </Space>
                  </Space> : null) : null}
                {ProfileContent.hasOwnProperty('scenes') ? (((ProfileContent.scenes[1] != null) && (UseSceneCount >= 2)) ?
                  <Space className='profile_subcontent'>
                    <Space direction='vertical' size={10}>
                      <Space className='profile_subtitle_space space_between'>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.scene.subtitle.scene_1_name')}</Text>
                        <Input className='profile_input_scene_name' value={ProfileContent.scenes[1].name} maxLength={10} onChange={(e) => onChange_SceneName(1, e.target.value)} />
                      </Space>
                      <Space direction='vertical' size={3}>
                        <Space>
                          <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.scene.component.lightness')}</Tag>
                          <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={(value) => onChange_SceneLightlevel(1, value)}
                            value={ProfileContent.scenes[1].light_level} />
                          <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={(value) => onChange_SceneLightlevel(1, value)}
                            value={ProfileContent.scenes[1].light_level} />
                        </Space>
                      </Space>
                    </Space>
                  </Space> : null) : null}
                {ProfileContent.hasOwnProperty('scenes') ? (((ProfileContent.scenes[2] != null) && (UseSceneCount >= 3)) ?
                  <Space className='profile_subcontent'>
                    <Space direction='vertical' size={10}>
                      <Space className='profile_subtitle_space space_between'>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.scene.subtitle.scene_1_name')}</Text>
                        <Input className='profile_input_scene_name' value={ProfileContent.scenes[2].name} maxLength={10} onChange={(e) => onChange_SceneName(2, e.target.value)} />
                      </Space>
                      <Space direction='vertical' size={3}>
                        <Space>
                          <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.scene.component.lightness')}</Tag>
                          <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={(value) => onChange_SceneLightlevel(2, value)}
                            value={ProfileContent.scenes[2].light_level} />
                          <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={(value) => onChange_SceneLightlevel(2, value)}
                            value={ProfileContent.scenes[2].light_level} />
                        </Space>
                      </Space>
                    </Space>
                  </Space> : null) : null}
              </Space>
          }, {
            key: 2,
            label: t('modal.profile.edit.tabs.sensor'),
            children:
              <Space direction='vertical'>
                <Space>
                  <Divider type='vertical' style={{ borderColor: '#808080' }} />
                  <Text className='profile_description'>{t('modal.profile.edit.description.sensor')}</Text>
                </Space>
                <Space className='space_between profile_subcontent'>
                  <Text className='profile_subtitle'>{t('modal.profile.edit.sensor.subtitle.setting')}</Text>
                  <Space direction='vertical'>
                    <Checkbox checked={UseMotionSensor} onChange={onChange_UseMotionSensor}>{t('modal.profile.edit.sensor.component.motion_sensor')}</Checkbox>
                    <Checkbox checked={UseAmbientLightSensor} onChange={onChange_UseAmbientLightSensor}>{t('modal.profile.edit.sensor.component.ambient_light_sensor')}</Checkbox>
                  </Space>
                </Space>

                {UseMotionSensor ?
                  <Line height={200} xField='time' yField='light_level' legend={false}
                    axis={{ x: { title: '시간', titleFill: '#FFFFFF', labelFill: '#FFFFFF' }, y: { title: '밝기', titleFill: '#FFFFFF', labelFill: '#FFFFFF' } }}
                    point={{ shapeField: 'circle', colorField: '#FFFFFF' }} style={{ stroke: '#FFFFFF' }}
                    data={[{
                      time: -10,
                      light_level: (ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)
                    }, {
                      time: 0,
                      light_level: (ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)
                    }, {
                      time: 0,
                      light_level: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.light_level : DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL)
                    }, {
                      time: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT),
                      light_level: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.light_level : DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL)
                    }, {
                      time: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT),
                      light_level: (ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.light_level : DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL)
                    }, {
                      time: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT) + (ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.timeout : DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT),
                      light_level: (ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.light_level : DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL)
                    }, {
                      time: (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT) + (ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.timeout : DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT),
                      light_level: (ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)
                    }, {
                      time: 10 + (ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT) + (ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.timeout : DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT),
                      light_level: (ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)
                    }]} /> : null}

                {(UseMotionSensor || UseAmbientLightSensor) ?
                  <>
                    <Space className='profile_subcontent'>
                      <Space direction='vertical' size={10}>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.sensor.subtitle.run')}</Text>
                        <Space direction='vertical' size={3}>
                          {UseMotionSensor ?
                            <>
                              <Space>
                                <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.lightness')}</Tag>
                                <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={onChange_Run_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('run') ? ProfileContent.run.light_level : DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL)} />
                                <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={onChange_Run_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('run') ? ProfileContent.run.light_level : DEFAULT_VALUE.LIGHT_LC.RUN.LIGHT_LEVEL)} />
                              </Space>
                              <Space>
                                <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.timeout')}</Tag>
                                <Slider className='profile_slider' min={1} max={600} marks={MARKS_TIMEOUT} onChange={onChange_Run_Timeout}
                                  value={(ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT)} />
                                <InputNumber className='profile_inputnumber' min={1} max={600} formatter={(value) => `${value}${t('modal.profile.edit.unit.sec')}`} parser={(value) => value.replace(t('modal.profile.edit.unit.sec'), '')} onChange={onChange_Run_Timeout}
                                  value={(ProfileContent.hasOwnProperty('run') ? ProfileContent.run.timeout : DEFAULT_VALUE.LIGHT_LC.RUN.TIMEOUT)} />
                              </Space>
                            </> : null}
                          {UseAmbientLightSensor ?
                            <Space>
                              <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.illuminance')}</Tag>
                              <Slider className='profile_slider' min={0} max={1000} marks={MARKS_AMBIENT_LIGHT} onChange={onChange_LuxLevel_Run}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.run : DEFAULT_VALUE.LIGHT_LC.RUN.AMBIENT_LIGHT)} />
                              <InputNumber className='profile_inputnumber' min={0} max={1000} formatter={(value) => `${value}lux`} parser={(value) => value.replace('lux', '')} onChange={onChange_LuxLevel_Run}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.run : DEFAULT_VALUE.LIGHT_LC.RUN.AMBIENT_LIGHT)} />
                            </Space> : null}
                        </Space>
                      </Space>
                    </Space>
                    <Space className='profile_subcontent'>
                      <Space direction='vertical' size={10}>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.sensor.subtitle.prolong')}</Text>
                        <Space direction='vertical' size={3}>
                          {UseMotionSensor ?
                            <>
                              <Space>
                                <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.lightness')}</Tag>
                                <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={onChange_Prolong_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.light_level : DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL)} />
                                <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={onChange_Prolong_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.light_level : DEFAULT_VALUE.LIGHT_LC.PROLONG.LIGHT_LEVEL)} />
                              </Space>
                              <Space>
                                <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.timeout')}</Tag>
                                <Slider className='profile_slider' min={1} max={600} onChange={onChange_Prolong_Timeout}
                                  value={(ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.timeout : DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT)} />
                                <InputNumber className='profile_inputnumber' min={0} max={600} formatter={(value) => `${value}${t('modal.profile.edit.unit.sec')}`} parser={(value) => value.replace(t('modal.profile.edit.unit.sec'), '')} onChange={onChange_Prolong_Timeout}
                                  value={(ProfileContent.hasOwnProperty('prolong') ? ProfileContent.prolong.timeout : DEFAULT_VALUE.LIGHT_LC.PROLONG.TIMEOUT)} />
                              </Space>
                            </> : null}
                          {UseAmbientLightSensor ?
                            <Space>
                              <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.illuminance')}</Tag>
                              <Slider className='profile_slider' min={0} max={1000} marks={MARKS_AMBIENT_LIGHT} onChange={onChange_LuxLevel_Prolong}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.prolong : DEFAULT_VALUE.LIGHT_LC.PROLONG.AMBIENT_LIGHT)} />
                              <InputNumber className='profile_inputnumber' min={0} max={1000} formatter={(value) => `${value}lux`} parser={(value) => value.replace('lux', '')} onChange={onChange_LuxLevel_Prolong}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.prolong : DEFAULT_VALUE.LIGHT_LC.PROLONG.AMBIENT_LIGHT)} />
                            </Space> : null}
                        </Space>
                      </Space>
                    </Space>
                    <Space className='profile_subcontent'>
                      <Space direction='vertical' size={10}>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.sensor.subtitle.prolong')}</Text>
                        <Space direction='vertical' size={3}>
                          {UseMotionSensor ?
                            <>
                              <Space>
                                <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.lightness')}</Tag>
                                <Slider className='profile_slider' min={0} max={100} marks={MARKS_PERCENT} onChange={onChange_Standby_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)} />
                                <InputNumber className='profile_inputnumber' min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} onChange={onChange_Standby_LightLevel}
                                  value={(ProfileContent.hasOwnProperty('standby') ? ProfileContent.standby.light_level : DEFAULT_VALUE.LIGHT_LC.STANDBY.LIGHT_LEVEL)} />
                              </Space>
                            </> : null}
                          {UseAmbientLightSensor ?
                            <Space>
                              <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.sensor.component.illuminance')}</Tag>
                              <Slider className='profile_slider' min={0} max={1000} marks={MARKS_AMBIENT_LIGHT} onChange={onChange_LuxLevel_Standby}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.standby : DEFAULT_VALUE.LIGHT_LC.STANDBY.AMBIENT_LIGHT)} />
                              <InputNumber className='profile_inputnumber' min={0} max={1000} formatter={(value) => `${value}lux`} parser={(value) => value.replace('lux', '')} onChange={onChange_LuxLevel_Standby}
                                value={(ProfileContent.hasOwnProperty('lux_level') ? ProfileContent.lux_level.standby : DEFAULT_VALUE.LIGHT_LC.STANDBY.AMBIENT_LIGHT)} />
                            </Space> : null}
                        </Space>
                      </Space>
                    </Space>
                  </> : null}
              </Space>
          }, {
            key: 3,
            label: t('modal.profile.edit.tabs.ctl'),
            children:
              <Space direction='vertical'>
                <Space>
                  <Divider type='vertical' style={{ borderColor: '#808080' }} />
                  <Text className='profile_description'>{t('modal.profile.edit.description.ctl')}</Text>
                </Space>
                <Space className='space_between profile_subcontent'>
                  <Text className='profile_subtitle'>{t('modal.profile.edit.ctl.subtitle.setting')}</Text>
                  <Checkbox checked={UseColorTunableLight} onChange={onChange_UseColorTunableLight}>{t('modal.profile.edit.ctl.component.color_tunable_light')}</Checkbox>
                </Space>

                {UseColorTunableLight ?
                  <>
                    <Space className='profile_subcontent'>
                      <Space direction='vertical' size={10}>
                        <Text className='profile_subtitle'>{t('modal.profile.edit.ctl.subtitle.temperature_range')}</Text>
                        <Space className='space_center' direction='vertical' size={3} style={{ width: 440 }}>
                          <Space>
                            <Slider className='profile_slider_ctl' range={true} min={1000} max={10000} step={100} marks={MARKS_LIGHT_TEMPERATURE} onChange={onChange_TemperatureRange}
                              value={[(ProfileContent.hasOwnProperty('temperature_range') ? ProfileContent.temperature_range.min : DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MIN),
                              (ProfileContent.hasOwnProperty('temperature_range') ? ProfileContent.temperature_range.max : DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MAX)]} />
                          </Space>
                          <Space>
                            <Space>
                              <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.ctl.component.min')}</Tag>
                              <InputNumber className='profile_inputnumber_ctl' min={1000} max={10000} step={100} formatter={(value) => `${value}K`} parser={(value) => value.replace('K', '')} onChange={(value) => onChange_TemperatureRange([value, null])}
                                value={(ProfileContent.hasOwnProperty('temperature_range') ? ProfileContent.temperature_range.min : DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MIN).toLocaleString()} />
                            </Space>
                            <Space style={{ marginLeft: 20 }}>
                              <Tag className='profile_subtag' color='#FFFFFF' style={{ color: '#000000' }}>{t('modal.profile.edit.ctl.component.max')}</Tag>
                              <InputNumber className='profile_inputnumber_ctl' min={1000} max={10000} step={100} formatter={(value) => `${value}K`} parser={(value) => value.replace('K', '')} onChange={(value) => onChange_TemperatureRange([null, value])}
                                value={(ProfileContent.hasOwnProperty('temperature_range') ? ProfileContent.temperature_range.max : DEFAULT_VALUE.LIGHT_CTL.TEMPERATURE.MAX).toLocaleString()} />
                            </Space>
                          </Space>
                        </Space>
                      </Space>
                    </Space>
                  </> : null}
              </Space>
          }]}>
        </Tabs>

        <Space style={{ width: '100%', justifyContent: 'end', marginTop: 10 }}>
          <Button onClick={onCancel_Modal}>{t('modal.button.cancel')}</Button>
          <Button type='primary' onClick={onOk_Modal}>{t('modal.button.ok')}</Button>
        </Space>
      </Modal >
    </>
  );
}